// src/components/UserListModal.js

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Avatar,
  Text,
  Spinner,
  Box,
  useToast,
  Badge,
  Flex,
  Tag,
} from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useAuth } from "../AuthContext";

const UserListModal = ({ isOpen, onClose }) => {
  const [users, setUsers] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const toast = useToast();
  const { user } = useAuth();

  const api_url = process.env.REACT_APP_API_BASE_URL;

  // Function to fetch users
  const fetchUsers = async (token = null) => {
    try {
      const jwtToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!jwtToken) {
        throw new Error("User is not authenticated.");
      }

      const response = await axios.get(`${api_url}/admin/users`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: {
          limit: 20, // You can adjust the limit as needed
          paginationToken: token,
        },
      });

      const fetchedUsers = response.data.users;
      const nextToken = response.data.paginationToken;

      setUsers((prevUsers) => [...prevUsers, ...fetchedUsers]);
      setPaginationToken(nextToken);
      setHasMore(Boolean(nextToken));
    } catch (err) {
      console.error("Error fetching users:", err);
      setError(err.message || "An error occurred while fetching users.");
      toast({
        title: "Error",
        description: err.response?.data?.message || "Failed to fetch users.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch initial users when modal opens
  useEffect(() => {
    if (isOpen) {
      setUsers([]);
      setPaginationToken(null);
      setHasMore(true);
      setError(null);
      setIsLoadingInitial(true);
      fetchUsers()
        .then(() => setIsLoadingInitial(false))
        .catch(() => setIsLoadingInitial(false));
    }
  }, [isOpen]);

  // Function to load more users
  const loadMoreUsers = () => {
    if (paginationToken) {
      setIsLoadingMore(true);
      fetchUsers(paginationToken)
        .then(() => setIsLoadingMore(false))
        .catch(() => setIsLoadingMore(false));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>All Users</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoadingInitial ? (
            <Flex justifyContent="center" mt={10}>
              <Spinner size="lg" color="teal.500" />
            </Flex>
          ) : error ? (
            <Text color="red.500" textAlign="center">
              {error}
            </Text>
          ) : users.length === 0 ? (
            <Text textAlign="center">No users found.</Text>
          ) : (
            <InfiniteScroll
              dataLength={users.length}
              next={loadMoreUsers}
              hasMore={hasMore}
              loader={
                <Flex justifyContent="center" mt={4}>
                  <Spinner size="md" color="teal.500" />
                </Flex>
              }
              height={500}
              endMessage={
                <Text textAlign="center" mt={4} color="gray.500">
                  You've reached the end of the list.
                </Text>
              }
              scrollThreshold={0.9}
            >
              <VStack spacing={4} align="stretch">
                {users.map((user) => (
                  <HStack
                    key={user.userId}
                    spacing={4}
                    p={3}
                    borderBottom="1px solid"
                    borderColor="gray.700"
                    _hover={{ bg: "gray.700" }}
                    borderRadius="md"
                  >
                    <Avatar
                      size="lg"
                      src={user.profile_picture || ""}
                      name={`${user.given_name} ${user.family_name}`}
                      bg="teal.500"
                    />
                    <Box flex="1">
                      <HStack justifyContent="space-between">
                        <Box>
                            <Text fontWeight="bold" fontSize="lg">
                                {user.given_name} {user.family_name}
                            </Text>
                            <Tag colorScheme="whiteAlpha" size="sm" fontWeight={600} mt={2}>
                                {user.points} DashPoints
                            </Tag>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                ))}
              </VStack>
            </InfiniteScroll>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} colorScheme="teal">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserListModal;
