// src/context/AddDashPointContext.js

import React, { createContext, useContext, useState } from "react";

const AddDashPointContext = createContext();

export const AddDashPointProvider = ({ children }) => {
  const [routeTitle, setRouteTitle] = useState("");
  const [routeDescription, setRouteDescription] = useState("");
  const [announcementTime, setAnnouncementTime] = useState("");
  const [runStartTime, setRunStartTime] = useState("");
  const [notificationPreferences, setNotificationPreferences] = useState({
    ANNOUNCEMENT: true,
    "24H_REMINDER": true,
    "1H_REMINDER": true,
    RUN_START: true,
  });
  const [routeGeoJSON, setRouteGeoJSON] = useState(null);
  const [gpxUploaded, setGpxUploaded] = useState(false);

  // Track route length in km
  const [routeLength, setRouteLength] = useState(0);

  const reset = () => {
    setRouteTitle("");
    setRouteDescription("");
    setAnnouncementTime("");
    setRunStartTime("");
    setNotificationPreferences({
      ANNOUNCEMENT: true,
      "24H_REMINDER": true,
      "1H_REMINDER": true,
      RUN_START: true,
    });
    setRouteGeoJSON(null);
    setGpxUploaded(false);
    setRouteLength(0);
  };

  return (
    <AddDashPointContext.Provider
      value={{
        routeTitle,
        setRouteTitle,
        routeDescription,
        setRouteDescription,
        announcementTime,
        setAnnouncementTime,
        runStartTime,
        setRunStartTime,
        notificationPreferences,
        setNotificationPreferences,
        routeGeoJSON,
        setRouteGeoJSON,
        gpxUploaded,
        setGpxUploaded,
        routeLength,
        setRouteLength,
        reset,
      }}
    >
      {children}
    </AddDashPointContext.Provider>
  );
};

export const useAddDashPoint = () => useContext(AddDashPointContext);
