// Login.js
import React, { useState } from "react";
import { useAuth } from "../AuthContext";
import { Navigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  Alert,
  AlertIcon,
  Flex,
  Spinner,
} from "@chakra-ui/react";

const Login = () => {
  const { user, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true); // Start loading

    try {
      await login(email, password);
    } catch (err) {
      setError(err.message || "Login failed");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  // Styling constants for black and white theme
  const bgColor = "black";
  const textColor = "white";
  const inputBg = "black";
  const inputBorderColor = "whiteAlpha.500";
  const inputFocusBorderColor = "white";
  const placeholderColor = "whiteAlpha.600";
  const alertBg = "whiteAlpha.200";
  const buttonBg = "whiteAlpha.200";
  const buttonHoverBg = "whiteAlpha.300";
  const buttonActiveBg = "whiteAlpha.400";

  return (
    <Flex
      minHeight="100vh"
      align="center"
      justify="center"
      bg={bgColor}
      color={textColor}
      px="4"
    >
      <Box
        maxW="md"
        w="full"
        p="8"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="lg"
        bg="black"
        borderColor="whiteAlpha.300"
      >
        <Heading
          as="h1"
          mb="6"
          textAlign="center"
          fontSize={{ base: "3xl", md: "4xl" }}
          fontWeight="extrabold"
          letterSpacing="tight"
          color="white"
        >
          DashPoint.
        </Heading>

        {error && (
          <Alert
            status="error"
            mb="4"
            borderRadius="md"
            bg={alertBg}
            color="white"
            alignItems="center"
          >
            <AlertIcon color="white" />
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <VStack spacing="6">
            <FormControl isRequired>
              <FormLabel fontSize="sm" htmlFor="email" color="white">
                Email
              </FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
                borderColor={inputBorderColor}
                focusBorderColor={inputFocusBorderColor}
                _focus={{
                  boxShadow: "0 0 0 1px white",
                }}
                transition="border-color 0.2s, box-shadow 0.2s"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontSize="sm" htmlFor="password" color="white">
                Password
              </FormLabel>
              <Input
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: placeholderColor }}
                borderColor={inputBorderColor}
                focusBorderColor={inputFocusBorderColor}
                _focus={{
                  boxShadow: "0 0 0 1px white",
                }}
                transition="border-color 0.2s, box-shadow 0.2s"
              />
            </FormControl>

            <Button
              type="submit"
              size="lg"
              width="full"
              bg={buttonBg}
              color={textColor}
              _hover={{ bg: buttonHoverBg }}
              _active={{ bg: buttonActiveBg }}
              isLoading={isLoading} // Show loading spinner
              loadingText="Logging in"
              spinner={<Spinner size="sm" color="white" />}
              transition="background-color 0.2s"
            >
              Login
            </Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default Login;
