// src/components/RouteDetail.js

import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Correct Import Path
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"; // Import Mapbox Draw CSS
import MapboxDraw from "@mapbox/mapbox-gl-draw"; // Import Mapbox Draw
import axios from "axios";
import { useAuth } from "../AuthContext";
import {
  useToast,
  Button,
  Box,
  Text,
  Spinner,
  Flex,
  Heading,
  VStack,
  HStack,
  Badge,
  IconButton,
  Tooltip,
  Skeleton,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Stack,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import { FiArrowLeft, FiEdit, FiSave, FiX } from "react-icons/fi";
import { chakra } from "@chakra-ui/react";

// Set Mapbox access token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Rating Component (Assuming it's needed)
const Rating = ({ rating, numReviews }) => {
  return (
    <Box display="flex" alignItems="center">
      {Array(5)
        .fill("")
        .map((_, i) => {
          const roundedRating = Math.round(rating * 2) / 2;
          if (roundedRating - i >= 1) {
            return (
              <chakra.span key={i}>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  height="1em"
                  width="1em"
                  color={i < rating ? "teal.500" : "gray.300"}
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.974a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.388 2.464a1 1 0 00-.364 1.118l1.286 3.974c.3.921-.755 1.688-1.54 1.118L10 13.347l-3.388 2.464c-.784.57-1.838-.197-1.54-1.118l1.286-3.974a1 1 0 00-.364-1.118L2.98 9.401c-.783-.57-.38-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.286-3.974z" />
                </svg>
              </chakra.span>
            );
          }
          if (roundedRating - i === 0.5) {
            return (
              <chakra.span key={i}>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  height="1em"
                  width="1em"
                  color="teal.500"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.974a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.388 2.464a1 1 0 00-.364 1.118l1.286 3.974c.3.921-.755 1.688-1.54 1.118L10 13.347l-3.388 2.464c-.784.57-1.838-.197-1.54-1.118l1.286-3.974a1 1 0 00-.364-1.118L2.98 9.401c-.783-.57-.38-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.286-3.974z" />
                </svg>
              </chakra.span>
            );
          }
          return (
            <chakra.span key={i}>
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                color="gray.300"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.974a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.388 2.464a1 1 0 00-.364 1.118l1.286 3.974c.3.921-.755 1.688-1.54 1.118L10 13.347l-3.388 2.464c-.784.57-1.838-.197-1.54-1.118l1.286-3.974a1 1 0 00-.364-1.118L2.98 9.401c-.783-.57-.38-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.286-3.974z" />
              </svg>
            </chakra.span>
          );
        })}
      <Box as="span" ml="2" color="gray.600" fontSize="sm">
        {numReviews} review{numReviews > 1 && "s"}
      </Box>
    </Box>
  );
};

const RouteDetail = () => {
  const { id } = useParams(); // Get route ID from URL
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null); // Reference to Mapbox Draw
  const [route, setRoute] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [originalCoordinates, setOriginalCoordinates] = useState(null); // To store original route for cancel

  // Modal for Update Confirmation
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch Route Data
  useEffect(() => {
    const fetchRoute = async () => {
      const api_url = process.env.REACT_APP_API_BASE_URL;
      try {
        const token =
          user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
        if (!token) {
          throw new Error("User is not authenticated.");
        }

        const response = await axios.get(`${api_url}/route/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoute(response.data);
        console.log("Fetched Route:", response.data);
      } catch (error) {
        console.error("Error fetching route:", error);
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to fetch route details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchRoute();
    }
  }, [id, user, toast]);

  // Initialize Map
  useEffect(() => {
    if (!route || !mapboxgl.accessToken) return;

    // Ensure coordinates are present and valid
    if (
      !route.coordinates ||
      !Array.isArray(route.coordinates) ||
      route.coordinates.length === 0
    ) {
      toast({
        title: "Invalid Route Data",
        description: "The selected route does not contain valid coordinates.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Initialize Map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v11", // Dark map style
      center: [route.coordinates[0][0], route.coordinates[0][1]], // Starting position [lng, lat]
      zoom: 12,
    });

    mapRef.current = map;

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Add the route as a geojson source
    map.on("load", () => {
      // Add route as a source
      if (map.getSource("route")) {
        map.removeLayer("route-line");
        map.removeSource("route");
      }

      map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: route.coordinates,
          },
        },
      });

      // Add the route as a layer
      map.addLayer({
        id: "route-line",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#FF0000",
          "line-width": 4,
        },
      });

      // Add markers for start and end points
      const startPoint = route.coordinates[0];
      const endPoint = route.coordinates[route.coordinates.length - 1];

      // Start Marker
      new mapboxgl.Marker({ color: "green" })
        .setLngLat(startPoint)
        .setPopup(new mapboxgl.Popup().setHTML("<h3>Start Point</h3>"))
        .addTo(map);

      // End Marker
      new mapboxgl.Marker({ color: "red" })
        .setLngLat(endPoint)
        .setPopup(new mapboxgl.Popup().setHTML("<h3>End Point</h3>"))
        .addTo(map);

      // Fit the map to the route
      const bounds = route.coordinates.reduce(
        (b, c) => {
          return b.extend(c);
        },
        new mapboxgl.LngLatBounds(route.coordinates[0], route.coordinates[0])
      );

      map.fitBounds(bounds, { padding: 50 });
    });

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [route, toast]);

  // Initialize Mapbox Draw when entering edit mode
  useEffect(() => {
    if (!isEditMode || !mapRef.current) return;

    const map = mapRef.current;

    // Initialize Mapbox Draw
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // We allow editing and deleting existing lines
      controls: {
        line_string: true,
        trash: true,
      },
      defaultMode: "simple_select",
      styles: [
        // Active line style
        {
          id: "gl-draw-line-active",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "active", "true"],
          ],
          paint: {
            "line-color": "#FF0000",
            "line-dasharray": [0.2, 2],
            "line-width": 4,
          },
        },
        // Inactive line style
        {
          id: "gl-draw-line-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "active", "false"],
          ],
          paint: {
            "line-color": "#FF0000",
            "line-width": 4,
          },
        },
      ],
    });

    drawRef.current = draw;
    map.addControl(draw, "top-left");

    // Add the current route as a Draw feature with a specific id
    const feature = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route.coordinates,
      },
      id: "editable-route", // Assign a unique id
    };

    const featureId = draw.add(feature);
    console.log("Added feature with id:", featureId);

    if (!featureId) {
      toast({
        title: "Error",
        description: "Failed to initialize the editable route.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Enter direct_select mode with the correct featureId so we can edit vertices
    try {
      draw.changeMode("direct_select", { featureId });
    } catch (error) {
      console.error("Error entering direct_select mode:", error);
      toast({
        title: "Error",
        description: "Failed to enter edit mode.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    // Listen for draw changes (user moving vertices)
    map.on("draw.update", onDrawUpdate);
    map.on("draw.delete", handleDelete);

    // Cleanup function to remove the draw control
    return () => {
      if (map && drawRef.current) {
        map.removeControl(drawRef.current);
        drawRef.current = null;
      }
      map.off("draw.update", onDrawUpdate);
      map.off("draw.delete", handleDelete);
    };
  }, [isEditMode, route, toast]);

  // Called whenever a user moves a vertex or otherwise updates the line in Draw
  const onDrawUpdate = () => {
    if (!drawRef.current) return;
    const data = drawRef.current.getAll();

    // If for some reason the user deleted everything, warn them
    if (data.features.length === 0) {
      toast({
        title: "No route to edit",
        description: "Your route has been deleted. Click cancel if accidental.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // We do NOT immediately update the route state or the source here.
    // We'll only finalize the update on "Save".
    // This ensures that clicking "Cancel" reverts back to the original route.
  };

  // Handle Delete Event (when user clicks the trash button)
  const handleDelete = () => {
    toast({
      title: "Route Deleted",
      description: "The route has been removed from the map. Click cancel to revert.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    // We do NOT update the backend or the route state yet.
    // If user clicks "Cancel," original coordinates are restored.
  };

  // Toggle between viewing and editing mode
  const handleEditToggle = () => {
    if (isEditMode) {
      // Cancel editing and reset the route
      setIsEditMode(false);
      if (originalCoordinates) {
        // Revert to original
        setRoute((prevRoute) => ({
          ...prevRoute,
          coordinates: originalCoordinates,
        }));
        setOriginalCoordinates(null);
      }
    } else {
      // Enter edit mode and store original coordinates for potential revert
      setOriginalCoordinates(route.coordinates);
      setIsEditMode(true);
    }
  };

  // Finalize the save to the backend
  const handleSave = async () => {
    if (!drawRef.current || !mapRef.current) return;

    const map = mapRef.current;
    const data = drawRef.current.getAll();

    if (data.features.length === 0) {
      toast({
        title: "No route to save",
        description: "It looks like the route was removed or never drawn.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const updatedCoordinates = data.features[0].geometry.coordinates;

    // Update the map source so the user sees the final updated route
    if (map.getSource("route")) {
      map.getSource("route").setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: updatedCoordinates,
        },
      });
    }

    // Update route state in React
    setRoute((prevRoute) => ({
      ...prevRoute,
      coordinates: updatedCoordinates,
    }));

    // Exit edit mode
    setIsEditMode(false);

    // Send the updated route to the backend
    try {
      const api_url = process.env.REACT_APP_API_BASE_URL;
      const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!token) {
        throw new Error("User is not authenticated.");
      }

      await axios.put(
        `${api_url}/route/${id}`,
        {
          routeName: route.routeName,
          routeDescription: route.routeDescription,
          announcementDateTime: route.announcementDateTime,
          runStartDateTime: route.runStartDateTime,
          coordinates: updatedCoordinates,
          routeLength: route.routeLength, // Include if necessary
          notificationPreferences: route.notificationPreferences,
          dashPin: route.dashPin,
          routeDate: route.routeDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Route Updated",
        description: "The route has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating route:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to update the route.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Revert to original coordinates in case of error
      if (originalCoordinates) {
        setRoute((prevRoute) => ({
          ...prevRoute,
          coordinates: originalCoordinates,
        }));
        setOriginalCoordinates(null);
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg="black"
      >
        <Spinner size="xl" color="teal.500" />
      </Box>
    );
  }

  if (!route) {
    return (
      <Box p={4} textAlign="center" bg="black" color="white" height="100vh">
        <Text fontSize="xl" mb={4}>
          Route not found.
        </Text>
        <Button
          leftIcon={<FiArrowLeft />}
          colorScheme="teal"
          onClick={() => navigate("/dashboard")}
        >
          Go Back to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box position="relative" height="100vh" width="100%">
      {/* Map Container */}
      <Box ref={mapContainerRef} style={{ width: "100%", height: "100%" }} />

      {/* Route Details Sidebar */}
      <Box
        position="absolute"
        top={{ base: "auto", md: "20px" }}
        bottom={{ base: "20px", md: "auto" }}
        right={{ base: "20px", md: "20px" }}
        left={{ base: "20px", md: "auto" }}
        bg="rgba(26, 32, 44, 0.9)" // Semi-transparent dark background
        color="white"
        p={6}
        borderRadius="md"
        boxShadow="lg"
        maxW={{ base: "90%", md: "sm" }}
        width={{ base: "auto", md: "sm" }}
        overflowY="auto"
        maxH={{ base: "60vh", md: "80vh" }}
      >
        <VStack align="start" spacing={4} w="100%">
          {/* Route Name */}
          <Heading size="md" display="flex" alignItems="center">
            🛣️ {route.routeName}
          </Heading>

          {/* Route Description */}
          <Box>
            <Text fontSize="sm" mb={2}>
              📝 {route.routeDescription}
            </Text>
            {route.staticMapImageUrl && (
              <Image
                src={route.staticMapImageUrl}
                alt={`Map of ${route.routeName}`}
                width="100%"
                height="250px"
                objectFit="cover"
                fallback={<Skeleton height="200px" width="100%" />}
              />
            )}
          </Box>

          <Divider borderColor="gray.700" />

          {/* Announcement Date and Time */}
          <HStack align="start">
            <Text fontSize="lg">📢</Text>
            <Box>
              <Text fontWeight="bold">Announcement:</Text>
              <Text fontSize="sm">
                {new Date(route.announcementDateTime).toLocaleString()}
              </Text>
            </Box>
          </HStack>

          {/* Run Start Date and Time */}
          <HStack align="start">
            <Text fontSize="lg">🏁</Text>
            <Box>
              <Text fontWeight="bold">Run Start:</Text>
              <Text fontSize="sm">
                {new Date(route.runStartDateTime).toLocaleString()}
              </Text>
            </Box>
          </HStack>

          {/* Notification Preferences */}
          <Box>
            <Text fontWeight="bold">Notification Preferences:</Text>
            <VStack align="start" spacing={1}>
              <HStack>
                <Checkbox
                  isChecked={route.notificationPreferences?.ANNOUNCEMENT}
                  isDisabled
                >
                  Announcement
                </Checkbox>
              </HStack>
              <HStack>
                <Checkbox
                  isChecked={route.notificationPreferences?.["24H_REMINDER"]}
                  isDisabled
                >
                  24-Hour Reminder
                </Checkbox>
              </HStack>
              <HStack>
                <Checkbox
                  isChecked={route.notificationPreferences?.["1H_REMINDER"]}
                  isDisabled
                >
                  1-Hour Reminder
                </Checkbox>
              </HStack>
              <HStack>
                <Checkbox
                  isChecked={route.notificationPreferences?.RUN_START}
                  isDisabled
                >
                  Run Start
                </Checkbox>
              </HStack>
            </VStack>
          </Box>

          {/* Dash Pin */}
          <HStack align="start">
            <Text fontSize="lg">📍</Text>
            <Box>
              <Text fontWeight="bold">Dash Pin:</Text>
              <Text fontSize="sm">{route.dashPin}</Text>
            </Box>
          </HStack>

          {/* Route Length */}
          <HStack align="center">
            <Text fontSize="lg">🛤️</Text>
            <Box>
              <Text fontWeight="bold">Length:</Text>
              <Text fontSize="sm">{route.routeLength} km</Text>
            </Box>
          </HStack>

          {/* Edit Buttons */}
          <HStack spacing={2} mt={4}>
            {!isEditMode ? (
              <Tooltip label="Edit Route">
                <IconButton
                  icon={<FiEdit />}
                  colorScheme="blue"
                  variant="outline"
                  onClick={handleEditToggle}
                  aria-label="Edit Route"
                />
              </Tooltip>
            ) : (
              <HStack spacing={2}>
                <Tooltip label="Save Changes">
                  <IconButton
                    icon={<FiSave />}
                    colorScheme="green"
                    onClick={onOpen}
                    aria-label="Save Route"
                  />
                </Tooltip>
                <Tooltip label="Cancel Editing">
                  <IconButton
                    icon={<FiX />}
                    colorScheme="red"
                    onClick={handleEditToggle}
                    aria-label="Cancel Editing"
                  />
                </Tooltip>
              </HStack>
            )}
          </HStack>
        </VStack>
      </Box>

      {/* Edit Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white" borderRadius="md">
          <ModalHeader>Confirm Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to save the changes to this route?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                handleSave();
                onClose();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RouteDetail;
