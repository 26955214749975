// src/App.js

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./AuthContext";
import { AddDashPointProvider } from "./context/AddDashPointContext";

import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import RouteDetail from "./components/RouteDetail";
import AddDashPointMap from "./pages/AddDashPointMap"; // The Map Page

// ProtectedRoute component for guarding routes
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

const App = () => {
  return (
    <AuthProvider>
      <AddDashPointProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Protected Dashboard Route */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {/* Protected Route Detail */}
          <Route
            path="/route/:id"
            element={
              <ProtectedRoute>
                <RouteDetail />
              </ProtectedRoute>
            }
          />
          {/* Add DashPoint Map Page (step 2 in the flow) */}
          <Route
            path="/add-dashpoint/map"
            element={
              <ProtectedRoute>
                <AddDashPointMap />
              </ProtectedRoute>
            }
          />
          {/* Default route to redirect to login */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </AddDashPointProvider>
    </AuthProvider>
  );
};

export default App;
