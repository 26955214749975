// src/components/AddDashPointModal.jsx

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  FormErrorMessage,
  useToast,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAddDashPoint } from "../context/AddDashPointContext";

const AddDashPointModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    routeTitle,
    setRouteTitle,
    routeDescription,
    setRouteDescription,
    announcementTime,
    setAnnouncementTime,
    runStartTime,
    setRunStartTime,
    notificationPreferences,
    setNotificationPreferences,
    reset,
  } = useAddDashPoint();

  // Form validation errors
  const [errors, setErrors] = useState({
    routeTitle: "",
    routeDescription: "",
    announcementTime: "",
    runStartTime: "",
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  // Validate the user input
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      routeTitle: "",
      routeDescription: "",
      announcementTime: "",
      runStartTime: "",
    };

    if (!routeTitle.trim()) {
      newErrors.routeTitle = "Route Title is required.";
      valid = false;
    } else if (routeTitle.length > 100) {
      newErrors.routeTitle = "Route Title must be less than 100 characters.";
      valid = false;
    }

    if (!routeDescription.trim()) {
      newErrors.routeDescription = "Route Description is required.";
      valid = false;
    } else if (routeDescription.length > 500) {
      newErrors.routeDescription =
        "Route Description must be less than 500 characters.";
      valid = false;
    }

    if (!announcementTime) {
      newErrors.announcementTime = "Announcement Time is required.";
      valid = false;
    } else {
      const selectedAnnouncementTime = new Date(announcementTime);
      const currentTime = new Date();
      if (selectedAnnouncementTime <= currentTime) {
        newErrors.announcementTime = "Announcement Time must be in the future.";
        valid = false;
      }
    }

    if (!runStartTime) {
      newErrors.runStartTime = "Run Start Time is required.";
      valid = false;
    } else {
      const selectedRunStartTime = new Date(runStartTime);
      const selectedAnnouncementTime = new Date(announcementTime);
      if (selectedRunStartTime <= selectedAnnouncementTime) {
        newErrors.runStartTime =
          "Run Start Time must be after Announcement Time.";
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  // Handle notification preference changes
  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotificationPreferences({
      ...notificationPreferences,
      [name]: checked,
    });
  };

  // Move to map page if valid
  const handleProceedToMap = () => {
    if (!validateForm()) {
      toast({
        title: "Validation Errors",
        description: "Please fix the errors before proceeding.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // If valid, close the modal and navigate to map page
    onClose();
    navigate("/add-dashpoint/map");
  };

  // Dark theme colors
  const cardBg = "#1A202C"; // Chakra's gray.800
  const cardBorder = "#2D3748"; // Chakra's gray.700
  const textColor = "#E2E8F0"; // Chakra's gray.200
  const inputBg = "#2D3748"; // Chakra's gray.700
  const buttonBg = "#2B6CB0"; // Chakra's blue.600
  const buttonHover = "#2C5282"; // Chakra's blue.700

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent bg={cardBg} color={textColor} borderRadius="md">
        <ModalHeader borderBottom="1px solid #2D3748">
          Create New DashPoint
        </ModalHeader>
        <ModalCloseButton color={textColor} />
        <ModalBody>
          {/* Single step for Title, Description, Announcement Time, Run Start Time, Notification Preferences */}
          <VStack spacing={4} align="stretch">
            <FormControl isRequired isInvalid={!!errors.routeTitle}>
              <FormLabel color={textColor}>Route Title</FormLabel>
              <Input
                placeholder="Enter route title"
                value={routeTitle}
                onChange={(e) => setRouteTitle(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: "gray.400" }}
              />
              {errors.routeTitle && (
                <FormErrorMessage>{errors.routeTitle}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.routeDescription}>
              <FormLabel color={textColor}>Route Description</FormLabel>
              <Textarea
                placeholder="Enter route description"
                value={routeDescription}
                onChange={(e) => setRouteDescription(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: "gray.400" }}
              />
              {errors.routeDescription && (
                <FormErrorMessage>{errors.routeDescription}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.announcementTime}>
              <FormLabel color={textColor}>Announcement Time</FormLabel>
              <Input
                type="datetime-local"
                value={announcementTime}
                onChange={(e) => setAnnouncementTime(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: "gray.400" }}
              />
              {errors.announcementTime && (
                <FormErrorMessage>{errors.announcementTime}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.runStartTime}>
              <FormLabel color={textColor}>Run Start Time</FormLabel>
              <Input
                type="datetime-local"
                value={runStartTime}
                onChange={(e) => setRunStartTime(e.target.value)}
                bg={inputBg}
                color={textColor}
                _placeholder={{ color: "gray.400" }}
              />
              {errors.runStartTime && (
                <FormErrorMessage>{errors.runStartTime}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel color={textColor}>Notification Preferences</FormLabel>
              <VStack align="start">
                <Checkbox
                  name="ANNOUNCEMENT"
                  isChecked={notificationPreferences.ANNOUNCEMENT}
                  onChange={handleNotificationChange}
                  colorScheme="teal"
                  color={textColor}
                >
                  Enable Announcement Notification
                </Checkbox>
                <Checkbox
                  name="24H_REMINDER"
                  isChecked={notificationPreferences["24H_REMINDER"]}
                  onChange={handleNotificationChange}
                  colorScheme="teal"
                  color={textColor}
                >
                  Enable 24-Hour Reminder
                </Checkbox>
                <Checkbox
                  name="1H_REMINDER"
                  isChecked={notificationPreferences["1H_REMINDER"]}
                  onChange={handleNotificationChange}
                  colorScheme="teal"
                  color={textColor}
                >
                  Enable 1-Hour Reminder
                </Checkbox>
                <Checkbox
                  name="RUN_START"
                  isChecked={notificationPreferences.RUN_START}
                  onChange={handleNotificationChange}
                  colorScheme="teal"
                  color={textColor}
                >
                  Enable Run Start Notification
                </Checkbox>
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid #2D3748">
          <HStack spacing={4}>
            <Button
              onClick={handleClose}
              variant="outline"
              borderColor="#2D3748"
              color={textColor}
              _hover={{ bg: "#2D3748" }}
            >
              Cancel
            </Button>
            <Button
              bg={buttonBg}
              color="white"
              _hover={{ bg: buttonHover }}
              onClick={handleProceedToMap}
            >
              Proceed to Map
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddDashPointModal;
