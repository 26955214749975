import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import {
  useToast,
  Button,
  Box,
  Text,
  Image,
  SimpleGrid,
  VStack,
  HStack,
  Flex,
  Badge,
  chakra,
  useColorModeValue,
  Heading,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Skeleton,
  Tag,
  Divider,
  IconButton,
  AvatarBadge,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiChevronDown, FiMoreHorizontal, FiPlus, FiUsers } from "react-icons/fi";

import UpdateRouteModal from "./UpdateRouteModal";
import AddDashPointModal from "./AddDashPointModal"; // Import the new modal
import UserListModal from "./UserListModal"; // Import the new modal

// A small helper component for handling the announcement countdown
function AnnouncementCountdown({ announcementDateTime }) {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const target = new Date(announcementDateTime).getTime();
      const diff = target - now;

      if (diff <= 0) {
        setTimeLeft("The announcement time has passed!");
        clearInterval(intervalId);
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (diff % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [announcementDateTime]);

  return (
    <Tag colorScheme="whiteAlpha" size="lg">
      {timeLeft}
    </Tag>
  );
}

const Dashboard = () => {
  const [routes, setRoutes] = useState([]);
  const [selectedRouteId, setSelectedRouteId] = useState(null);
  const [attendanceList, setAttendanceList] = useState([]);
  const { user, logout } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  // Loading States
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);

  // Modal States
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isAddDashPointModalOpen, setIsAddDashPointModalOpen] = useState(false); // New state for Add DashPoint
  const [isUserListModalOpen, setIsUserListModalOpen] = useState(false); // <-- New modal state

  // Profile States
  const [profilePicture, setProfilePicture] = useState("");
  const [profileData, setProfileData] = useState({});

  // Editing States
  const [isEditing, setIsEditing] = useState(false);
  const [updateRouteData, setUpdateRouteData] = useState({
    routeId: null,
    routeName: "",
    routeDescription: "",
    announcementDateTime: null,
    runStartDateTime: null, // Added runStartDateTime
    notificationPreferences: null, // Ensure notificationPreferences is included
  });

  // Define colors at the top level
  const bgColor = useColorModeValue("gray.700", "gray.800");
  const headerBgColor = "gray.800"; // Fixed dark background for header
  const headerTextColor = "white"; // Light text color for header
  const cardBg = useColorModeValue("gray.800", "gray.900");
  const cardTextColor = "white";
  const buttonColorScheme = "teal";
  const buttonBgColor = "teal.500";
  const buttonHoverBgColor = "teal.600";

  // Fetch User Profile
  useEffect(() => {
    const fetchUserProfile = async () => {
      const api_url = process.env.REACT_APP_API_BASE_URL;
      try {
        setIsLoadingProfile(true);
        const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
        if (!token) {
          throw new Error("User is not authenticated.");
        }

        const response = await axios.get(`${api_url}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("User profile:", response.data);
        setProfilePicture(response.data.profile_picture);
        setProfileData(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        toast({
          title: "Error fetching profile.",
          description: "There was an error fetching your profile.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoadingProfile(false);
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user, toast]);

  // Handle Profile Picture Upload
  const handleProfilePictureChange = async (base64data) => {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    try {
      const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!token) {
        throw new Error("User is not authenticated.");
      }

      const response = await axios.post(
        `${api_url}/profile/picture`,
        {
          imageData: base64data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Profile picture updated successfully");
        setProfilePicture(
          `${process.env.REACT_APP_S3_BUCKET_URL}/${response.data.file_name}`
        );
        toast({
          title: "Profile picture updated.",
          description: "Your profile picture has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(
        "Error uploading profile picture:",
        error.response?.data || error
      );
      toast({
        title: "Error uploading profile picture.",
        description: "There was an error uploading your profile picture.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch Routes
  useEffect(() => {
    const fetchRoutes = async () => {
      const api_url = process.env.REACT_APP_API_BASE_URL;
      try {
        setIsLoadingRoutes(true);
        const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
        if (!token) {
          throw new Error("User is not authenticated.");
        }

        const response = await axios.get(`${api_url}/routes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoutes(response.data);
        console.log("Routes:", response.data);
      } catch (error) {
        console.error("Error fetching routes:", error);
        toast({
          title: "Error fetching routes.",
          description: "There was an error fetching the routes.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoadingRoutes(false);
      }
    };

    if (user) {
      fetchRoutes();
    }
  }, [user, toast]);

  // Handle Route Selection from Sidebar
  const handleRouteSelect = (routeId) => {
    setSelectedRouteId(routeId);
    // Additional logic if needed
  };

  // Handle Delete Route
  const handleDeleteRoute = async (routeId) => {
    if (!routeId) {
      toast({
        title: "No Route Selected",
        description: "Please select a route to delete.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // New confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this route?"
    );
    if (!confirmDelete) {
      return; // Exit if the user cancels
    }

    const api_url = process.env.REACT_APP_API_BASE_URL;
    try {
      const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!token) {
        throw new Error("User is not authenticated.");
      }

      await axios.delete(`${api_url}/route/${routeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoutes(routes.filter((route) => route.routeId !== routeId));
      setSelectedRouteId(null);
      toast({
        title: "Route Deleted",
        description: "The selected route has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting route:", error);
      toast({
        title: "Deletion Failed",
        description: "Failed to delete the route.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle Update Route
  const handleUpdateRoute = async (routeId) => {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    try {
      const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!token) {
        throw new Error("User is not authenticated.");
      }

      console.log("Fetching route data for routeId:", routeId);
      console.log("Using token:", token);

      const response = await axios.get(`${api_url}/route/${routeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const route = response.data;
      openUpdateModal(route);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.error("Error fetching route data:", errorMessage);
      toast({
        title: "Error fetching route data.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Open Update Modal with Route Data
  const openUpdateModal = (route) => {
    setUpdateRouteData({
      routeId: route.routeId,
      routeName: route.routeName,
      routeDescription: route.routeDescription,
      announcementDateTime: route.announcementDateTime,
      runStartDateTime: route.runStartDateTime,
      notificationPreferences: route.notificationPreferences,
    });
    setIsUpdateModalOpen(true);
  };

  // Handle View Route
  const handleViewRoute = (routeId) => {
    navigate(`/route/${routeId}`); // Navigate to the route detail page
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login"); // Redirect to login page after logout
    } catch (error) {
      console.error("Logout failed:", error);
      toast({
        title: "Logout Failed",
        description: "There was an error logging out. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" height="100vh" flexDirection="column" bg="black">
      {/* Header */}
      <Box
        bg={headerBgColor}
        px={4}
        py={3}
        boxShadow="md"
        position="sticky"
        top="0"
        zIndex="1000"
      >
        <Flex alignItems="center" justifyContent="space-between">
          {/* Left side of the header */}
          <Heading size="md" color={headerTextColor}>
            DashPoint.
          </Heading>

          {/* Right side of the header */}
          <HStack spacing={4} ml="auto">
            {/* New "View Users" Button */}
            <Button
              colorScheme="blue"
              bg="blue.600"
              size={"sm"}
              _hover={{ bg: "blue.700" }}
              onClick={() => setIsUserListModalOpen(true)}
              leftIcon={<FiUsers />} // Icon for the button
            >
              View Users
            </Button>

            <Button
              colorScheme={buttonColorScheme}
              bg="gray.700"
              size={"sm"}
              _hover={{ bg: buttonHoverBgColor }}
              onClick={() => setIsAddDashPointModalOpen(true)}
              leftIcon={<FiPlus />} // Add the icon to the button
            >
              Add DashPoint
            </Button>
            <Menu>
              <MenuButton
                as={Box}
                colorScheme={buttonColorScheme}
                size={"sm"}
                p={1}
                rounded={"full"}
                // bg hover color
                bg="gray.600"
                _hover={{ bg: "gray.700" }}
                cursor={"pointer"}
              >
                <Avatar
                  size="xs"
                  name={profileData?.name}
                  src={profilePicture}
                >
                  <AvatarBadge boxSize="1.25em" bg="green.500" />
                </Avatar>
                <Heading
                  display={{ base: "none", md: "inline" }}
                  fontSize="sm"
                  mx={4}
                >
                  Hello, {profileData?.given_name}
                </Heading>
              </MenuButton>
              <MenuList bg="gray.700">
                {/* Logout Button */}
                <MenuItem
                  onClick={handleLogout}
                  bg={useColorModeValue("gray.800", "gray.700")}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>
      {/* Main Content */}
      <Box
        flex="1"
        p={4}
        overflowY="auto"
        bg={useColorModeValue("gray.900", "gray.800")}
      >
        {isLoadingRoutes ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Spinner size="xl" color="teal.500" />
          </Flex>
        ) : routes.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Text color="gray.300">No routes available. Add a new DashPoint!</Text>
          </Flex>
        ) : (
          <SimpleGrid columns={[1, 2, 3, 4]} spacing={6}>
            {routes.map((route) => (
              <Box
                key={route.routeId}
                bg={cardBg}
                maxW="sm"
                borderRadius="lg"
                overflow="hidden"
                boxShadow="lg"
                borderWidth="1px"
                borderColor="gray.700"
                position="relative"
                _hover={{
                  boxShadow: "2xl",
                  transform: "translateY(-5px)",
                  transition: "0.2s",
                }}
              >
                {/* Static Map Image */}
                <Box position="relative">
                  <Image
                    src={route.staticMapImageUrl}
                    alt={`Map of ${route.routeName}`}
                    width="100%"
                    height="250px"
                    objectFit="cover"
                    fallback={<Skeleton height="200px" />}
                  />
                  {/* Route Length Tag */}
                  <Tag
                    colorScheme="whiteAlpha"
                    position="absolute"
                    bottom={2} // Adjust as needed
                    left={2}   // Adjust as needed
                    zIndex={1}
                  >
                    {route.routeLength} km
                  </Tag>

                  <Tag
                    colorScheme="whiteAlpha"
                    position="absolute"
                    // bottom right corner
                    bottom={2}
                    right={2}
                    zIndex={1}
                    fontWeight={"bold"}
                  >
                    DashPin {route.dashPin}
                  </Tag>
                </Box>
                
                {/* Date Tag in the top right corner */}
                <Tag
                  colorScheme="whiteAlpha"
                  position="absolute"
                  top={2}
                  right={2}
                  zIndex={1}
                >
                  {new Date(route.routeDate).toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Tag>

                <Box p="4">
                  <Heading as="h3" size="md" color={cardTextColor} mb={2}>
                    {route.routeName}
                  </Heading>

                  <Text color="gray.300" noOfLines={2} mb={3}>
                    {route.routeDescription}
                  </Text>

                  <Divider my={2} />

                  <SimpleGrid columns={2} spacing={4} mb={4}>
                    <Box>
                      <Text color="white" fontSize="sm" mb={2} fontWeight="bold" textAlign={"center"}>
                        Notification 🔔
                      </Text>
                      <HStack spacing={2} justifyContent="center" alignItems="center">
                        {new Date(route.announcementDateTime) > new Date() ? (
                          <AnnouncementCountdown
                            announcementDateTime={route.announcementDateTime}
                          />
                        ) : (
                          <Tag colorScheme="whiteAlpha" size="lg">
                            {new Date(route.announcementDateTime).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </Tag>
                        )}
                      </HStack>
                    </Box>

                    <Box>
                      <Text color="white" fontSize="sm" mb={2} fontWeight="bold" textAlign={"center"}>
                        Run Start 🏃
                      </Text>
                      <HStack spacing={2} justifyContent="center" alignItems="center">
                        <Tag colorScheme="whiteAlpha" size="lg">
                          {new Date(route.runStartDateTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </Tag>
                      </HStack>
                    </Box>
                  </SimpleGrid>

                  <Divider my={2} />

                  <HStack spacing={2} mt={6}>
                    <Button
                      size="sm"
                      colorScheme="white"
                      onClick={() => handleViewRoute(route.routeId)}
                      width="full" // Make View button full width
                      rounded={"full"}
                      border={"1px solid"}
                      variant={"outline"}
                    >
                      View Route
                    </Button>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        size="sm"
                        colorScheme="white"
                        icon={<FiMoreHorizontal />}
                        rounded={"full"}
                        border={"1px solid"}
                        variant={"outline"}
                      />
                      <MenuList bg="gray.700">
                        <MenuItem
                          onClick={() => handleUpdateRoute(route.routeId)}
                          bg="gray.800"
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDeleteRoute(route.routeId)}
                          color="red.500"
                          bg="gray.800"
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>
      {/* Update Route Modal */}
      <UpdateRouteModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        updateRouteData={updateRouteData}
        setUpdateRouteData={setUpdateRouteData}
        onUpdateSuccess={(updatedRoute) => {
          setRoutes(
            routes.map((route) =>
              route.routeId === updatedRoute.routeId ? updatedRoute : route
            )
          );
          toast({
            title: "Route Updated",
            description: "The route has been updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }}
      />

      {/* Add DashPoint Modal */}
      <AddDashPointModal
        isOpen={isAddDashPointModalOpen}
        onClose={() => setIsAddDashPointModalOpen(false)}
        onRouteCreated={(newRoute) => {
          setRoutes([...routes, newRoute]);
          toast({
            title: "DashPoint Created",
            description: "New DashPoint has been created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }}
      />

      {/* User List Modal */}
      <UserListModal
        isOpen={isUserListModalOpen}
        onClose={() => setIsUserListModalOpen(false)}
      />
    </Box>
  );
};

export default Dashboard;
