// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import awsConfig from "./awsConfig";
import { jwtDecode } from "jwt-decode"; // Updated import

const AuthContext = createContext();

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Check if a user is already logged in by checking the stored session
  useEffect(() => {
    const checkUserSession = () => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            setUser(null);
            cognitoUser.signOut();
          } else {
            const idToken = session.getIdToken().getJwtToken();
            const decodedToken = jwtDecode(idToken); // Updated usage
            const groups = decodedToken["cognito:groups"] || [];
            if (groups.includes("Admin")) {
              setUser(cognitoUser);
            } else {
              setUser(null);
              cognitoUser.signOut();
            }
          }
        });
      } else {
        setUser(null);
      }
    };

    checkUserSession();
  }, []);

  // Function to handle email-based login
  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      // Convert email to lowercase
      const lowerCaseEmail = email.toLowerCase();

      const authenticationDetails = new AuthenticationDetails({
        Username: lowerCaseEmail,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: lowerCaseEmail,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          const idToken = session.getIdToken().getJwtToken();
          const decodedToken = jwtDecode(idToken); // Updated usage
          const groups = decodedToken["cognito:groups"] || [];
          if (groups.includes("Admin")) {
            setUser(cognitoUser);
            resolve(session);
          } else {
            // User is not in Admin group
            cognitoUser.signOut();
            reject(new Error("Access denied: You are not authorized to access this application."));
          }
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const logout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
