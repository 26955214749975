import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  FormErrorMessage,
  useToast,
  Checkbox,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useAuth } from "../AuthContext";

const UpdateRouteModal = ({
  isOpen,
  onClose,
  updateRouteData,
  setUpdateRouteData,
  onUpdateSuccess, // Callback to refresh routes or update state in parent
}) => {
  const { user } = useAuth();
  const toast = useToast();
  const [routeName, setRouteName] = useState("");
  const [routeDescription, setRouteDescription] = useState("");
  const [announcementDateTime, setAnnouncementDateTime] = useState("");
  const [runStartDateTime, setRunStartDateTime] = useState("");
  const [notificationPreferences, setNotificationPreferences] = useState({
    ANNOUNCEMENT: true,
    "24H_REMINDER": true,
    "1H_REMINDER": true,
    RUN_START: true,
  });
  const [errors, setErrors] = useState({
    routeName: "",
    routeDescription: "",
    announcementDateTime: "",
    runStartDateTime: "",
    agreement: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agreement, setAgreement] = useState(false);

  // Dynamic colors based on the current color mode
  const modalBg = useColorModeValue("gray.800", "gray.700");
  const modalTextColor = useColorModeValue("white", "gray.100");
  const inputBg = useColorModeValue("gray.600", "gray.500");
  const inputTextColor = useColorModeValue("white", "gray.100");
  const borderColor = useColorModeValue("gray.500", "gray.400");

  // Utility function to format Date object to "YYYY-MM-DDTHH:MM" for input
  const formatDateTimeLocal = (date) => {
    if (!date) return "";
    const pad = (num) => String(num).padStart(2, "0");
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Check if announcementDateTime is in the past
  const isAnnouncementInPast = announcementDateTime
    ? new Date(announcementDateTime) < new Date()
    : false;

  // Populate form fields when modal opens with existing route data
  useEffect(() => {
    if (updateRouteData && isOpen) {
      setRouteName(updateRouteData.routeName || "");
      setRouteDescription(updateRouteData.routeDescription || "");
      setAnnouncementDateTime(
        updateRouteData.announcementDateTime
          ? formatDateTimeLocal(new Date(updateRouteData.announcementDateTime))
          : formatDateTimeLocal(new Date())
      );
      setRunStartDateTime(
        updateRouteData.runStartDateTime
          ? formatDateTimeLocal(new Date(updateRouteData.runStartDateTime))
          : formatDateTimeLocal(new Date(Date.now() + 3 * 60 * 60 * 1000)) // default to +3 hours
      );
      const initialNotificationPreferences = updateRouteData.notificationPreferences || {
        ANNOUNCEMENT: true,
        "24H_REMINDER": true,
        "1H_REMINDER": true,
        RUN_START: true,
      };
      setNotificationPreferences(initialNotificationPreferences);

      // Reset agreement and errors
      setAgreement(false);
      setErrors({
        routeName: "",
        routeDescription: "",
        announcementDateTime: "",
        runStartDateTime: "",
        agreement: "",
      });
    }
  }, [updateRouteData, isOpen]);

  // Effect to handle announcementDateTime changes
  useEffect(() => {
    if (isAnnouncementInPast) {
      setNotificationPreferences((prev) => ({
        ...prev,
        ANNOUNCEMENT: false,
      }));
      setAgreement(false);
      setErrors((prev) => ({ ...prev, agreement: "" }));
    }
  }, [announcementDateTime, isAnnouncementInPast]);

  const handleClose = () => {
    // Reset form fields and close the modal
    setRouteName("");
    setRouteDescription("");
    setAnnouncementDateTime("");
    setRunStartDateTime("");
    setNotificationPreferences({
      ANNOUNCEMENT: true,
      "24H_REMINDER": true,
      "1H_REMINDER": true,
      RUN_START: true,
    });
    setAgreement(false);
    setErrors({
      routeName: "",
      routeDescription: "",
      announcementDateTime: "",
      runStartDateTime: "",
      agreement: "",
    });
    setUpdateRouteData({
      routeId: null,
      routeName: "",
      routeDescription: "",
      announcementDateTime: null,
      runStartDateTime: null,
      notificationPreferences: null,
    });
    onClose();
  };

  // Validate the user input
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      routeName: "",
      routeDescription: "",
      announcementDateTime: "",
      runStartDateTime: "",
      agreement: "",
    };

    if (!routeName.trim()) {
      newErrors.routeName = "Route Name is required.";
      valid = false;
    } else if (routeName.length > 100) {
      newErrors.routeName = "Route Name must be less than 100 characters.";
      valid = false;
    }

    if (!routeDescription.trim()) {
      newErrors.routeDescription = "Route Description is required.";
      valid = false;
    } else if (routeDescription.length > 500) {
      newErrors.routeDescription =
        "Route Description must be less than 500 characters.";
      valid = false;
    }

    if (!announcementDateTime) {
      newErrors.announcementDateTime = "Announcement Date & Time is required.";
      valid = false;
    }

    if (!runStartDateTime) {
      newErrors.runStartDateTime = "Run Start Date & Time is required.";
      valid = false;
    } else {
      const selectedRunStartTime = new Date(runStartDateTime);
      const selectedAnnouncementTime = new Date(announcementDateTime);
      if (selectedRunStartTime <= selectedAnnouncementTime) {
        newErrors.runStartDateTime =
          "Run Start Time must be after Announcement Time.";
        valid = false;
      }
    }

    if (isAnnouncementInPast && !agreement) {
      newErrors.agreement =
        "You must agree that Announcement Notifications are disabled.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // Handle notification preference changes
  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotificationPreferences({
      ...notificationPreferences,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api_url = process.env.REACT_APP_API_BASE_URL;

    if (!validateForm()) {
      toast({
        title: "Validation Errors",
        description: "Please fix the errors before updating.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const routeId = updateRouteData.routeId;
      const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
      if (!token) {
        throw new Error("User is not authenticated.");
      }

      // If announcementDateTime is in the past, ensure ANNOUNCEMENT notification is false
      const finalNotificationPreferences = { ...notificationPreferences };
      if (isAnnouncementInPast) {
        finalNotificationPreferences.ANNOUNCEMENT = false;
      }

      const payload = {
        routeName,
        routeDescription,
        announcementDateTime: new Date(announcementDateTime).toISOString(),
        runStartDateTime: new Date(runStartDateTime).toISOString(),
        notificationPreferences: finalNotificationPreferences,
      };

      const response = await axios.put(`${api_url}/route/${routeId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "Route Updated",
          description:
            response.data?.message || "The route has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Update local state and pass updated route to parent
        const updatedRoute = {
          ...updateRouteData,
          routeName,
          routeDescription,
          announcementDateTime: new Date(announcementDateTime).toISOString(),
          runStartDateTime: new Date(runStartDateTime).toISOString(),
          notificationPreferences: finalNotificationPreferences,
        };
        // Callback to refresh routes in Dashboard with updated route
        onUpdateSuccess && onUpdateSuccess(updatedRoute);
        // Close the modal
        handleClose();
      } else {
        throw new Error("Failed to update the route.");
      }
    } catch (error) {
      console.error("Error updating route:", error.response?.data || error.message);
      toast({
        title: "Update Failed",
        description:
          error.response?.data?.message || "Failed to update the route.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent bg={modalBg} color={modalTextColor}>
        <ModalHeader>Update Route</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <FormControl
                id="routeName"
                isRequired
                isInvalid={!!errors.routeName}
              >
                <FormLabel>Route Name</FormLabel>
                <Input
                  placeholder="Enter route name"
                  value={routeName}
                  onChange={(e) => setRouteName(e.target.value)}
                  bg={inputBg}
                  color={inputTextColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: "teal.500" }}
                  _focus={{
                    borderColor: "teal.500",
                    boxShadow: "outline",
                  }}
                />
                {errors.routeName && (
                  <FormErrorMessage>{errors.routeName}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                id="routeDescription"
                isRequired
                isInvalid={!!errors.routeDescription}
              >
                <FormLabel>Route Description</FormLabel>
                <Textarea
                  placeholder="Enter route description"
                  value={routeDescription}
                  onChange={(e) => setRouteDescription(e.target.value)}
                  bg={inputBg}
                  color={inputTextColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: "teal.500" }}
                  _focus={{
                    borderColor: "teal.500",
                    boxShadow: "outline",
                  }}
                />
                {errors.routeDescription && (
                  <FormErrorMessage>
                    {errors.routeDescription}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                id="announcementDateTime"
                isRequired
                isInvalid={!!errors.announcementDateTime}
              >
                <FormLabel>Announcement Date & Time</FormLabel>
                <Input
                  type="datetime-local"
                  value={announcementDateTime}
                  onChange={(e) => setAnnouncementDateTime(e.target.value)}
                  bg={inputBg}
                  color={inputTextColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: "teal.500" }}
                  _focus={{
                    borderColor: "teal.500",
                    boxShadow: "outline",
                  }}
                />
                {errors.announcementDateTime && (
                  <FormErrorMessage>
                    {errors.announcementDateTime}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                id="runStartDateTime"
                isRequired
                isInvalid={!!errors.runStartDateTime}
              >
                <FormLabel>Run Start Date & Time</FormLabel>
                <Input
                  type="datetime-local"
                  value={runStartDateTime}
                  onChange={(e) => setRunStartDateTime(e.target.value)}
                  bg={inputBg}
                  color={inputTextColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: "teal.500" }}
                  _focus={{
                    borderColor: "teal.500",
                    boxShadow: "outline",
                  }}
                  min={announcementDateTime}
                />
                {errors.runStartDateTime && (
                  <FormErrorMessage>
                    {errors.runStartDateTime}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Notification Preferences</FormLabel>
                <VStack align="start">
                  <Checkbox
                    name="ANNOUNCEMENT"
                    isChecked={notificationPreferences.ANNOUNCEMENT}
                    onChange={handleNotificationChange}
                    colorScheme="teal"
                    isDisabled={isAnnouncementInPast}
                  >
                    Enable Announcement Notification
                  </Checkbox>
                  <Checkbox
                    name="24H_REMINDER"
                    isChecked={notificationPreferences["24H_REMINDER"]}
                    onChange={handleNotificationChange}
                    colorScheme="teal"
                  >
                    Enable 24-Hour Reminder
                  </Checkbox>
                  <Checkbox
                    name="1H_REMINDER"
                    isChecked={notificationPreferences["1H_REMINDER"]}
                    onChange={handleNotificationChange}
                    colorScheme="teal"
                  >
                    Enable 1-Hour Reminder
                  </Checkbox>
                  <Checkbox
                    name="RUN_START"
                    isChecked={notificationPreferences.RUN_START}
                    onChange={handleNotificationChange}
                    colorScheme="teal"
                  >
                    Enable Run Start Notification
                  </Checkbox>
                </VStack>
              </FormControl>

              {isAnnouncementInPast && (
                <FormControl isRequired isInvalid={!!errors.agreement}>
                  <Checkbox
                    isChecked={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                    colorScheme="teal"
                  >
                    I understand that Announcement Notifications are disabled
                    because the Announcement Date & Time is in the past.
                  </Checkbox>
                  {errors.agreement && (
                    <FormErrorMessage>{errors.agreement}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={isSubmitting}
              loadingText="Updating"
            >
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateRouteModal;
