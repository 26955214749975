// src/components/AddDashPointMap.jsx

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import MapGL, { NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { DOMParser } from "@xmldom/xmldom";
import * as toGeoJSON from "togeojson";
import * as turf from "@turf/turf";
import axios from "axios";

import { useAuth } from "../AuthContext"; // <-- Import useAuth
import { useAddDashPoint } from "../context/AddDashPointContext";

// Set the token for mapbox-gl
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const AddDashPointMap = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const mapRef = useRef(null); // react-map-gl map instance
  const drawRef = useRef(null); // reference to MapboxDraw instance
  const markersRef = useRef([]); // Ref to store marker instances

  const [mode, setMode] = useState("draw"); // "draw" or "upload"
  const [isEditing, setIsEditing] = useState(false); // track if user is editing an uploaded route
  const [currentRouteLength, setCurrentRouteLength] = useState(0); // Real-time route length

  // Pull from context
  const {
    routeTitle,
    routeDescription,
    announcementTime,
    runStartTime, // Obtained from context
    notificationPreferences, // Obtained from context
    routeGeoJSON,
    setRouteGeoJSON,
    routeLength,
    setRouteLength,
    gpxUploaded,
    setGpxUploaded,
  } = useAddDashPoint();

  // Get the user object (and token) from AuthContext
  const { user } = useAuth();

  // Form validation errors (only if needed)
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Check if user tries to come here without filling out the modal
  useEffect(() => {
    if (!routeTitle || !routeDescription || !announcementTime || !runStartTime) {
      toast({
        title: "Missing Details",
        description:
          "Please fill out the route details before accessing the map.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      navigate("/dashboard");
    }
  }, [routeTitle, routeDescription, announcementTime, runStartTime, navigate, toast]);

  // Initialize Mapbox Draw only when mode is "draw"
  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    if (mode === "draw" && !drawRef.current) {
      const drawControl = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          line_string: true,
          trash: true,
        },
        defaultMode: "draw_line_string",
        // Removed custom styles to use default MapboxDraw styles
      });
      drawRef.current = drawControl;
      map.addControl(drawControl, "top-left");

      // Event listeners for real-time length calculation
      map.on("draw.create", updateRouteLength);
      map.on("draw.update", updateRouteLength);
      map.on("draw.delete", updateRouteLength);
    } else if (mode === "upload") {
      // Remove draw if present
      if (drawRef.current) {
        map.removeControl(drawRef.current);
        drawRef.current = null;

        // Remove event listeners
        map.off("draw.create", updateRouteLength);
        map.off("draw.update", updateRouteLength);
        map.off("draw.delete", updateRouteLength);
      }
    }

    // Cleanup event listeners on unmount or mode change
    return () => {
      if (drawRef.current) {
        map.off("draw.create", updateRouteLength);
        map.off("draw.update", updateRouteLength);
        map.off("draw.delete", updateRouteLength);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Function to update real-time route length
  const updateRouteLength = () => {
    if (!drawRef.current) {
      setCurrentRouteLength(0);
      return;
    }

    const data = drawRef.current.getAll();
    if (data.features.length === 0) {
      setCurrentRouteLength(0);
      return;
    }

    const feature = data.features[0];
    if (feature.geometry.type !== "LineString") {
      setCurrentRouteLength(0);
      return;
    }

    try {
      const lengthInKm = turf.length(feature, { units: "kilometers" });
      setCurrentRouteLength(parseFloat(lengthInKm.toFixed(2)));
    } catch (error) {
      console.error("Error calculating real-time route length:", error);
      setCurrentRouteLength(0);
    }
  };

  // Whenever routeGeoJSON changes, update the map layer, markers, and route length
  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    // Remove any existing markers
    const removeMarkers = () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    };

    // If no route data, remove the layer/source
    if (!routeGeoJSON || routeGeoJSON.features.length === 0) {
      if (map.getSource("uploaded-route")) {
        if (map.getLayer("uploaded-route-line")) {
          map.removeLayer("uploaded-route-line");
        }
        map.removeSource("uploaded-route");
      }
      removeMarkers();
      setRouteLength(0);
      return;
    }

    // Remove old layer/source before adding new
    if (map.getSource("uploaded-route")) {
      if (map.getLayer("uploaded-route-line")) {
        map.removeLayer("uploaded-route-line");
      }
      map.removeSource("uploaded-route");
    }

    // Add source/layer for route
    map.addSource("uploaded-route", {
      type: "geojson",
      data: routeGeoJSON,
    });

    map.addLayer({
      id: "uploaded-route-line",
      type: "line",
      source: "uploaded-route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#FF0000",
        "line-width": 4,
      },
    });

    removeMarkers();

    const firstFeature = routeGeoJSON.features[0];
    if (firstFeature && firstFeature.geometry.type === "LineString") {
      const coords = firstFeature.geometry.coordinates;
      if (coords.length > 0) {
        // Start marker
        const startMarker = new mapboxgl.Marker({ color: "green" })
          .setLngLat(coords[0])
          .setPopup(new mapboxgl.Popup().setHTML("<h3>Start Point</h3>"))
          .addTo(map);
        markersRef.current.push(startMarker);

        // End marker
        const endMarker = new mapboxgl.Marker({ color: "red" })
          .setLngLat(coords[coords.length - 1])
          .setPopup(new mapboxgl.Popup().setHTML("<h3>End Point</h3>"))
          .addTo(map);
        markersRef.current.push(endMarker);

        // Fit map to route
        const bounds = coords.reduce(
          (b, c) => b.extend(c),
          new mapboxgl.LngLatBounds(coords[0], coords[0])
        );
        map.fitBounds(bounds, { padding: 50 });
      }
    }

    // Calculate route length in kilometers using turf
    try {
      const lengthInKm = turf.length(routeGeoJSON, { units: "kilometers" });
      setRouteLength(parseFloat(lengthInKm.toFixed(2)));
    } catch (error) {
      console.error("Error calculating route length:", error);
      setRouteLength(0);
    }
  }, [routeGeoJSON, setRouteLength]);

  // Handle GPX file upload
  const handleGpxFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const gpxString = e.target.result;
        const parser = new DOMParser();
        const gpxDOM = parser.parseFromString(gpxString, "application/xml");
        const geoJsonData = toGeoJSON.gpx(gpxDOM);

        if (!geoJsonData.features || geoJsonData.features.length === 0) {
          toast({
            title: "No Valid GPX Data",
            description: "No valid route or tracks found in this GPX.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Filter only lines
        const lineFeatures = geoJsonData.features.filter(
          (f) =>
            f.geometry.type === "LineString" ||
            f.geometry.type === "MultiLineString"
        );
        if (lineFeatures.length === 0) {
          toast({
            title: "No Valid GPX Data",
            description: "No valid line features found in this GPX file.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        const combinedGeoJson = {
          type: "FeatureCollection",
          features: lineFeatures,
        };
        setRouteGeoJSON(combinedGeoJson);
        setGpxUploaded(true);

        toast({
          title: "GPX Uploaded",
          description: "Route loaded from GPX file.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      };
      reader.readAsText(file);
    } catch (error) {
      console.error("Error parsing GPX file:", error);
      toast({
        title: "GPX Error",
        description: "Failed to parse GPX file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Clear route
  const handleClear = () => {
    if (drawRef.current) {
      drawRef.current.deleteAll();
    }
    setRouteGeoJSON(null);
    setGpxUploaded(false);
    setRouteLength(0);
    setCurrentRouteLength(0);

    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    toast({
      title: mode === "draw" ? "Route Cleared" : "Upload Cleared",
      description:
        mode === "draw"
          ? "All drawn routes have been cleared."
          : "Uploaded GPX route has been cleared.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  // Toggle edit mode for an uploaded route
  const handleEditToggle = () => {
    if (!routeGeoJSON || routeGeoJSON.features.length === 0) {
      toast({
        title: "No Route to Edit",
        description: "Upload a GPX route first.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const map = mapRef.current?.getMap();
    if (!map) return;

    if (!isEditing) {
      // Switch to draw mode
      if (map.getSource("uploaded-route")) {
        if (map.getLayer("uploaded-route-line")) {
          map.removeLayer("uploaded-route-line");
        }
        map.removeSource("uploaded-route");
      }

      if (!drawRef.current) {
        const drawControl = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            line_string: true,
            trash: true,
          },
          defaultMode: "draw_line_string",
        });
        drawRef.current = drawControl;
        map.addControl(drawRef.current, "top-left");
      }

      const mainFeature = routeGeoJSON.features[0];
      const addedIds = drawRef.current.add(mainFeature);

      try {
        drawRef.current.changeMode("direct_select", {
          featureId: addedIds[0],
        });
      } catch (err) {
        console.error("Error switching to direct_select:", err);
      }

      setIsEditing(true);
      toast({
        title: "Editing Enabled",
        description: "You can now edit the uploaded route.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } else {
      // Finish editing: retrieve updated data
      const data = drawRef.current.getAll();
      if (!data || data.features.length === 0) {
        toast({
          title: "No Route",
          description: "No route found in editing mode.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        setIsEditing(false);
        return;
      }

      setRouteGeoJSON(data);
      map.removeControl(drawRef.current);
      drawRef.current = null;

      setIsEditing(false);

      toast({
        title: "Editing Finished",
        description: "Your updated route is now saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Final create DashPoint API call
  const handleCreateDashPoint = async () => {
    // Make sure we have everything needed
    if (!routeTitle || !routeDescription || !announcementTime || !runStartTime) {
      toast({
        title: "Missing Fields",
        description: "Please return to the form and fill all route details.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (!routeGeoJSON || routeGeoJSON.features.length === 0) {
      toast({
        title: "No Route",
        description: "Please draw or upload a route first.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // ---- USE COGNITO SESSION INSTEAD OF LOCAL STORAGE ----
    const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
    if (!token) {
      toast({
        title: "Unauthorized",
        description: "No valid Cognito session found.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const api_url = process.env.REACT_APP_API_BASE_URL;
      // Convert routeGeoJSON -> coordinates array
      const firstFeature = routeGeoJSON.features[0];
      const coords = firstFeature.geometry.coordinates; // [ [lng, lat, elevation], [lng, lat, elevation], ... ]

      // Prepare the payload for your create-lambda
      const payload = {
        routeName: routeTitle,
        routeDescription: routeDescription,
        routeLength: parseFloat(routeLength),
        coordinates: coords,
        announcementDateTime: new Date(announcementTime).toISOString(),
        runStartDateTime: new Date(runStartTime).toISOString(),
        notificationPreferences: notificationPreferences,
      };

      const response = await axios.post(`${api_url}/route`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Create DashPoint response:", response.data);

      toast({
        title: "DashPoint Created",
        description:
          response.data?.message || "The DashPoint has been created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset form fields
      setRouteGeoJSON(null);
      setGpxUploaded(false);
      setRouteLength(0);
      setCurrentRouteLength(0);

      // Return to dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating DashPoint:", error.response?.data || error.message);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to create DashPoint.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // UI Colors
  const cardBg = "#1A202C";
  const cardBorder = "#2D3748";
  const textColor = "#E2E8F0";
  const inputBgColor = "#2D3748";
  const buttonBg = "#2B6CB0";
  const buttonHover = "#2C5282";
  const buttonRed = "#C53030";
  const buttonRedHover = "#9B2C2C";
  const buttonTeal = "#319795";
  const buttonTealHover = "#2C7A7B";
  const buttonBlue = "#3182CE";
  const buttonBlueHover = "#2B6CB0";

  return (
    <Box height="100vh" width="100%" position="relative" bg="black">
      {/* Sidebar for draw/upload controls */}
      <Box
        position="absolute"
        top="20px"
        right="20px"
        zIndex={2}
        bg={cardBg}
        p={6}
        borderRadius="lg"
        boxShadow="2xl"
        borderWidth="1px"
        borderColor={cardBorder}
        maxW="90%"
        width="300px"
        overflowY="auto"
        maxH="90vh"
      >
        <Tabs
          variant="enclosed"
          index={mode === "draw" ? 0 : 1}
          onChange={(index) => {
            if (index === 0) {
              // Switch to draw mode
              setMode("draw");
              handleClear();
            } else {
              // Switch to upload mode
              setMode("upload");
              setIsEditing(false);
              if (drawRef.current) {
                mapRef.current.getMap().removeControl(drawRef.current);
                drawRef.current = null;
              }
              handleClear();
            }
          }}
        >
          <TabList>
            <Tab
              _selected={{ color: "teal.300", borderColor: "teal.300" }}
              color="gray.300"
            >
              Draw Route
            </Tab>
            <Tab
              _selected={{ color: "teal.300", borderColor: "teal.300" }}
              color="gray.300"
            >
              Upload GPX
            </Tab>
          </TabList>

          <TabPanels>
            {/* Draw Tab */}
            <TabPanel>
              <VStack spacing={4} align="stretch">
                <Button
                  bg={buttonBg}
                  color="white"
                  _hover={{ bg: buttonHover }}
                  onClick={() => {
                    if (!drawRef.current) return;
                    const data = drawRef.current.getAll();
                    if (data.features.length === 0) {
                      toast({
                        title: "No Route Drawn",
                        description: "Please draw a route first.",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    // Save to context
                    setRouteGeoJSON(data);
                    toast({
                      title: "Route Saved",
                      description: "Your drawn route has been saved.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                  isFullWidth
                >
                  Save Drawn Route
                </Button>
                <Button
                  bg={buttonRed}
                  color="white"
                  _hover={{ bg: buttonRedHover }}
                  onClick={handleClear}
                  isFullWidth
                >
                  Clear
                </Button>
              </VStack>
            </TabPanel>

            {/* Upload Tab */}
            <TabPanel>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel color={textColor} fontSize="sm" marginBottom="0">
                    Upload GPX File
                  </FormLabel>
                  <Input
                    type="file"
                    accept=".gpx"
                    onChange={handleGpxFileUpload}
                    size="sm"
                    bg={inputBgColor}
                    color={textColor}
                    _hover={{ bg: "gray.600" }}
                  />
                </FormControl>
                <Button
                  bg={buttonBlue}
                  color="white"
                  _hover={{ bg: buttonBlueHover }}
                  onClick={handleEditToggle}
                  isFullWidth
                  disabled={!routeGeoJSON || routeGeoJSON.features.length === 0}
                >
                  {isEditing ? "Finish Editing" : "Edit Uploaded Route"}
                </Button>
                <Button
                  bg={buttonRed}
                  color="white"
                  _hover={{ bg: buttonRedHover }}
                  onClick={handleClear}
                  isFullWidth
                  disabled={!routeGeoJSON}
                >
                  Clear
                </Button>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* Button to finalize creation */}
        <Button
          bg={buttonTeal}
          color="white"
          _hover={{ bg: buttonTealHover }}
          mt={6}
          width="100%"
          onClick={handleCreateDashPoint}
          isLoading={isSubmitting}
          loadingText="Creating"
        >
          Create DashPoint
        </Button>
      </Box>

      {/* Display route length if we have a route */}
      {routeGeoJSON && routeGeoJSON.features.length > 0 && (
        <Box
          position="absolute"
          bottom="60px"
          right="20px"
          zIndex={2}
          bg={cardBg}
          p={3}
          borderRadius="md"
          boxShadow="lg"
          borderWidth="1px"
          borderColor={cardBorder}
        >
          <Text fontSize="sm" fontWeight="bold" color="teal.300">
            Route Length: {routeLength} km
          </Text>
        </Box>
      )}

      {/* Display real-time route length while drawing */}
      {mode === "draw" && currentRouteLength > 0 && (
        <Box
          position="absolute"
          bottom="20px"
          right="20px"
          zIndex={2}
          bg={cardBg}
          p={3}
          borderRadius="md"
          boxShadow="lg"
          borderWidth="1px"
          borderColor={cardBorder}
        >
          <Text fontSize="sm" fontWeight="bold" color="teal.300">
            Current Length: {currentRouteLength} km
          </Text>
        </Box>
      )}

      {/* The actual map */}
      <MapGL
        ref={mapRef}
        initialViewState={{
          longitude: -1.617439,
          latitude: 54.978252,
          zoom: 12,
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11" // Changed to default style
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <NavigationControl position="top-left" style={{ color: "white" }} />
      </MapGL>
    </Box>
  );
};

export default AddDashPointMap;
