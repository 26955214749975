import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // Import the main App component
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter for routing
import { ChakraProvider, extendTheme } from '@chakra-ui/react'; // Import ChakraProvider and extendTheme for theming

// Create a custom theme with black background and Helvetica font
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'black', // Set the global background color to black
        color: 'white', // Set the global text color to white
        fontFamily: 'Helvetica, Arial, sans-serif', // Use Helvetica as the font
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
